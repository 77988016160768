.Sponsor{
  margin-bottom: 5rem;
  transition: 0.3s;
}
.Sponsor:hover{
  transform: scale(1.1);
}
.Sponsor img{
    width: 25rem;
    display: inline-block;
    margin: 0;
}

.shead{
    font-size: 6rem;
    margin-bottom: 6rem;
   margin-top: -3rem;
}

.sponsorUS {
  margin-bottom: 7rem;
}

.sponsorUS a {
  color: aliceblue;
}

/* more smaller */
@media  (max-width: 570px){
  .shead{
    margin-top:0;
  }
  .Sponsor img{

  margin-bottom: 3rem;
}
}
  .Sponsor img{
 
   -webkit-animation: mover 1s infinite  alternate;
   animation: mover 1s infinite  alternate;
  }
  @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
  }
  @keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
  }
