@import url(https://db.onlinewebfonts.com/c/011860fc12a11332d2863a747de7bc32?family=Open+Sans+Regular);

.nav_bar {
  list-style-type: none;
  height: 50px;
  font-family: 'Open Sans Regular', sans-serif;
  font-size: 1.8rem;
  position: fixed;
  width: 100%;
  z-index: 10;
  transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #221F24;

  @media (min-width: 1000px) {
    height: 88px;
  }
}

  @media (min-width: 1000px) {
    .nav_bar-offset-crossed {
      background-color: #221F24;
      height: 72px;
      box-shadow: 0 8px 16px 0 rgb(255, 49, 49, 0.2);
    }
  }

  .s-open,
  .s-close {
    display: none;
  }
  .nav-inner {
    width: 30%;
  }
  ul {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    background-color: #221F24;
  }
  nav ul li {
    display: inline-block;
  }
  .links {
    color: rgb(231, 228, 228);
    transition: 0.5s;
    font-weight: 300;
    letter-spacing: 1.3px;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 1000px) {
    .ease {
      background: #221F24;
      height: 75%;
      opacity: 0.2;
      pointer-events: none;
    }
    ul {
      flex-direction: column;
      padding: 30px 0;
    }
    .s-open {
      display: inline;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: -1;
    }
    .s-close {
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

