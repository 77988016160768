@import url(https://db.onlinewebfonts.com/c/011860fc12a11332d2863a747de7bc32?family=Open+Sans+Regular);

.typing-div{
    margin-bottom: 9rem;
    margin-top: 2rem;

}
.typing-div h1{
    font-family: 'Open Sans Regular', sans-serif;
    font-size: 5rem;
    font-weight: 300;
    margin: 0;
    text-align: left;
}

@media  (max-width: 570px){
    .typing-div h1{
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
    }
}
@media  (max-width: 330px){   
    .typing-div h1{
        font-size: 2.5rem;
        font-weight: 700;
    }
  }
 