@import url(https://db.onlinewebfonts.com/c/011860fc12a11332d2863a747de7bc32?family=Open+Sans+Regular);

.logoS {
  width: 390px;
  height: 390px;
  margin-top: 0;
  padding: 0;
  position: relative;
  right: 130px;
}
.Logo {
  width: 60%;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: absolute;
}


.LogoSectionAbout {
  text-align: left;
  width: 80%;
  display: inline-block;
  position: relative;
  font-family: "Open Sans Regular", sans-serif;
}
.LogoSectionAbout h1 {
  font-weight: 700;
  text-align: left;
}

.Logo {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 2s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@media (max-width: 570px) {
  .logoS {
    margin-top: 10rem;
    margin-bottom: -10rem;
  }
  .Logo {
    margin: 0 auto;
  }
}

@media (max-width: 570px) {
  .logoS {
    margin-top: 10rem;
    margin-bottom: -10rem;
    /* background-color: black; */
  }
  .Logo {
    margin: 0 auto;
  }
}
