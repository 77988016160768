.social-icons-container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.social-icon {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: whitesmoke;
}
.footer {
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: whitesmoke;
}
.footer p {
  font-weight: 500;
  font-size: 1.5rem;
}
.footer a {
  color: aliceblue;
}
.footer img {
  width: 70%;
}
.footer_info {
  padding: 20px 0;
  background-color: #211E22;
}
.Register_a {
  padding: 70px 0;
  background-color: #211E22;
}
.Register_a a {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 570px) {
  .Register_a a {
    margin-left: 0;
    margin-right: 0;
  }
  .Volunteer {
    margin-top: 15px;
  }
  .social-icon {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.privacy-policy p {
  font-weight: 300;
  margin: 0;
  text-decoration: underline;
}

.template {
  position: fixed;
  bottom: 0;
  background-color: #5C5962;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 50px;
  justify-content: center;
  padding-top: 4px;
  &-left{
    width: 80%;
  }
  img {
    width: 15px;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
  p {
    margin: 0 auto;
    color: whitesmoke;
    @media (max-width: 570px) {
      font-size: 14px;
    }
  }
  a {
    color: #dcd8df;
  }
}
