@import url(https://db.onlinewebfonts.com/c/011860fc12a11332d2863a747de7bc32?family=Open+Sans+Regular);
@import url(https://db.onlinewebfonts.com/c/26afb49b8b7dda2c10686589d6fc7b55?family=Architype+Aubette+W90);

.h1 {
  margin: 0;
}

button {
  width: 22rem;
  height: 6rem;
  border: none;
  border-radius: 19px;
  font-size: 2.1rem;
  color: whitesmoke;
  background-color: #1886db;
  font-family: 'Open Sans Regular', sans-serif;
  font-weight: 300;
  position: relative;
}

.join_disco {
  width: 25rem;
  border: 2px solid whitesmoke;
  background-color: transparent;
}

.join_disco i {
  margin-right: 10px;
}

.join_dis {
  margin-bottom: 29px;
  margin-top: 19px;
  display: inline;
}

.buttom-group {
  width: 100%;
  overflow: auto;
  margin-top: 3vh;
  margin-bottom: 3rem;
  text-align: center;
}

.register {
  margin-right: 3rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #b9ae1d 0%, #ff3131 100%);
}

.typed {
  margin-left: 10vh;
}

.AboutMe h2 {
  font-size: 12rem;
  font-family: 'Architype Aubette W90', sans-serif;
  text-transform: uppercase;
  min-width: 225px;
  margin-top: -2.7vh;
  margin-left: 10vh;
}

.dynamic-colour {
  opacity: 1;
  /*background: url(../../containers/Home/assets/background-pattern.png) repeat-x;*/
  background-image: linear-gradient(
          -225deg,
          #ec371f 0%,
          #ee6e37 12.5%,
          #EE5D6C 25%,
          #CE4993 37.5%,
          #6A0D83 50%,
          #CE4993 62.5%,
          #EE5D6C 75%,
          #ee6e37 87.5%,
          #ec371f 100%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: dynamic-colour-text 10s linear infinite;
}

@keyframes dynamic-colour-text {
  to {
    background-position: 200% center;
  }
}

.glitch {
  width: 70%;
  animation: glitch 1s linear infinite;
}

@keyframes glitch {
  2%, 64% {
    transform: translate(2px,0) skew(0deg);
  }
  4%, 60% {
    transform: translate(-2px,0) skew(0deg);
  }
  62% {
    transform: translate(0,0) skew(5deg);
  }
}

.glitch:before,
.glitch:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.glitch:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%, 64% {
    transform: translate(2px,-2px);
  }
  4%, 60% {
    transform: translate(-2px,2px);
  }
  62% {
    transform: translate(13px,-1px) skew(-13deg);
  }
}

.glitch:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {
  2%, 64% {
    transform: translate(-2px,0);
  }
  4%, 60% {
    transform: translate(-2px,0);
  }
  62% {
    transform: translate(-22px,5px) skew(21deg);
  }
}

p {
  font-size: 2rem;
  line-height: 1.6;
  font-weight: 300;
}

.Myinfo {
  text-align: left;
  font-family: 'Open Sans Regular', sans-serif;
}

.Myinfo p {
  width: 75%;
  text-align: center;
  margin: 0 auto;
}

/* Over lay effect */

.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: whitesmoke;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .3s ease;
  border-radius: 12px;
}

.overlay-text {
  font-weight: 300;
  color: #211e22;
  font-size: 17px;
  font-family: 'Open Sans Regular', sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

button:hover .overlay {
  width: 100%;
  left: 0;
}

@media (max-width: 1200px) {
  .AboutMe h2 {
     font-size: 10rem;
  }
}

/* main mobile */

@media (max-width: 760px) {
  .AboutMe h2 {
    margin-left: 0;
  }
  .typed {
    margin-left: 0;
  }
}

@media (max-width: 570px) {
  .Myinfo p {
    width: 100%;
  }
  .AboutMe h2 {
    margin-left: 0;
    font-size: 7rem;
  }
  .register {
    margin-left: 0;
    margin-top: 3vh;
  }
  .Myinfo {
    margin-left: 0;
  }
  .Myinfo p {
    font-size: 2.1rem;
  }
}

@media (max-width: 330px) {
  .register {
    margin-left: 0;
    margin-top: 3vh;
  }
  .AboutMe h1 {
    margin-left: 0;
    font-size: 2.7em;
  }
  .AboutMe h2 {
    margin-left: 0;
  }
  .Myinfo p {
    font-size: 1.8rem;
  }
}