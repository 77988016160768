.pdf-frame {
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
    margin-top: 50px;

    @media (min-width: 1000px) {
        margin-top: 88px;
        height: calc(100% - 88px);
    }
}