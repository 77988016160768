@import url(https://db.onlinewebfonts.com/c/011860fc12a11332d2863a747de7bc32?family=Open+Sans+Regular);

.Prizes {
    width: fit-content;
    text-align: left;
    margin: 1.7rem auto 0;
}
.PrizeHeading h1 {
    font-size: 5rem;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: 'Open Sans Regular', sans-serif;
}

.Prizes p {
    color: rgb(145, 143, 143);
}

.Prizes .gold {
    color: rgba(255, 241, 17, 0.75);
}

.Prizes .silver {
    color: rgba(157, 157, 157, 0.75);
}

.Prizes .bronze {
    color: rgba(201, 112, 66, 0.75);
}

.Prizes .blue {
    color: rgba(64, 155, 192, 0.75);
}

.Prizes .green {
    color: rgba(84, 206, 66, 0.75);
}

.Prizes .purple {
    color: rgba(138, 48, 225, 0.75);
}

.Prizes .red {
    color: rgba(250, 32, 32, 0.75);
}
