@import url(https://db.onlinewebfonts.com/c/011860fc12a11332d2863a747de7bc32?family=Open+Sans+Regular);

.body {
  font-family: "Open Sans Regular", sans-serif;
}

.Whole_div {
  background-repeat: round;
  background-size: auto 100vh;
  font-family: 'Open Sans Regular', sans-serif;
  position: relative;
  color: whitesmoke;
}
.mediaInfo {
  margin-top: 10vh;
  background-size: auto 100vh;
  background-repeat: round;
}
.color_sectiom {
  background-color: #211E22;
  padding-bottom: 5rem;
}
.container-fluid {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  text-align: center;
  font-size: 5rem;
}
.Row {
  text-align: center;
}

.info {
  margin-top: 20vh;
  color: whitesmoke;
}

.shine{
  opacity: 1;
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
          -280deg,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.33) 77%,
          rgba(255, 255, 255, 0.7) 92%,
          rgba(255, 255, 255, 0.0) 100%
  );
  background-size: 250% 250%;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  animation: dynamic-colour-text 1.75s linear infinite;
}

.d-image img {
  width: 100%;
  position: relative;
  top: 0;
  transition: top 0.2s linear;
}
.d-image img:hover {
  opacity: 0.9;
  top: 10px;
}
.logoSection {
  text-align: center;
  padding: 20vh 10vh 0 10vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
}
.members {
  padding: 0 10vh 0 10vh;
}
.prizesection {
  text-align: center;
  padding: 0 4vh 0 4vh;
  border-radius: 14px;
  margin: 4vh 0 0;
  background-color: #211E22;
}

.Prizes{
  width: 100%;
}

.sponsorSection {
  padding: 17vh 18vh 0 18vh;
  margin-top: 0;
}
.faqsSection {
  text-align: center;
  padding: 17vh 18vh 0 18vh;
  margin: -16vh auto 0;
}

@media (max-width: 570px) {
  .mediaInfo {
    margin-top: 0;
  }
  .Whole_div {
    background-size: auto 100vh;
  }

  .faqsSection {
    padding: 0;
    margin-top: 0;
  }
  .members {
    margin: 0;
    padding: 0;
  }

  .prizesection {
    margin: 5vh 0 0 0;
    padding: 17vh 0 0 4vh;
  }
  .sponsorSection {
    padding: 0;
    margin: 12rem auto;
  }
  .logoSection {
    text-align: center;
    padding: 20vh 0 0 0;
  }
}

.scroll-to-top-button {
  display: none;
  background-color: #313131;
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  padding-top: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
}

.scroll-to-top-button.show {
  display: block;
}
